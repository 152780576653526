import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import type { Advogado } from "../types";

interface AdvogadoDetailsDialogProps {
  advogado: Advogado | null;
  open: boolean;
  onClose: () => void;
}

const AdvogadoDetailsDialog: React.FC<AdvogadoDetailsDialogProps> = ({
  advogado,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detalhes do Advogado</DialogTitle>
      <DialogContent>
        {advogado && (
          <>
            <p>
              <strong>Nome:</strong> {advogado.nome}
            </p>
            <p>
              <strong>OAB:</strong> {advogado.oab}
            </p>
            <p>
              <strong>Telefone:</strong> {advogado.telefone}
            </p>
            <p>
              <strong>Celular:</strong> {advogado.celular}
            </p>
            <p>
              <strong>Email:</strong> {advogado.email}
            </p>
            <p>
              <strong>Site:</strong> {advogado.site}
            </p>
            <p>
              <strong>Ativo:</strong> {advogado.ativo}
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvogadoDetailsDialog;
