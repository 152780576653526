import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import * as React from "react";

import { Advogado } from "../types";

interface AdvogadoGridProps {
  advogados: Advogado[];
  selectedAdvogados: Advogado[];
  onSelectionChange: (selected: Advogado[]) => void;
}

const AdvogadoGrid: React.FC<AdvogadoGridProps> = ({
  advogados,
  selectedAdvogados,
  onSelectionChange,
}) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "nome", headerName: "Nome", width: 230 },
    { field: "oab", headerName: "OAB", width: 150 },
    {
      field: "ativo",
      headerName: "Ativo",
      width: 130,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: params.value === "S" ? "green" : "#FA3F17",
                width: 120,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {params.value === "S" ? "ATIVO" : "INATIVO"}
            </Box>
          </div>
        );
      },
    },
  ];

  const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
    //const selectedIds = selectionModel as number[];
    //const selected = advogados.filter((adv) => selectedIds.includes(adv.id));
    //onSelectionChange(selected);
  };

  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        rows={advogados}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
};

export default AdvogadoGrid;
