import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  CircularProgress,
} from "@mui/material";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AdvogadoGrid from "../../components/advogadoGrid";
import { Layout } from "../../components/layout";
import { recuperarTodasAreas } from "../../services/area";
import { recuperarTodosAssuntos } from "../../services/assunto";
import { recuperarTodasClasses } from "../../services/classe";
import {
  recuperarProcessoPorId,
  editarProcesso,
} from "../../services/processo";
import { recuperarTodasVaras } from "../../services/vara";
import {
  ProcessoResponseDTO,
  Classe,
  Assunto,
  Vara,
  Area,
  Advogado,
  AxiosResponse,
  Response,
} from "../../types";

const TelaProcesso: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [classe, setClasse] = useState<Classe[]>([]);
  const [assunto, setAssunto] = useState<Assunto[]>([]);
  const [vara, setVara] = useState<Vara[]>([]);
  const [area, setArea] = useState<Area[]>([]);
  const [advogados, setAdvogados] = useState<Advogado[]>([]);
  const [processo, setProcesso] = useState<ProcessoResponseDTO>({
    id: "",
    classeId: null,
    assuntoId: null,
    varaId: null,
    areaId: null,
    importacaoId: null,
    dataCriacao: "",
    numero: "",
    conteudo: "",
    autor: "",
    reu: "",
    valor: null,
    advogados: [],
  });

  useEffect(() => {
    if (id) {
      Promise.all([
        recuperarTodasAreas()
          .then((response: AxiosResponse<Area[]>) => {
            if (response) {
              setArea(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              //signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar areas: " + error.message);
            }
          }),
        recuperarTodosAssuntos()
          .then((response: AxiosResponse<Assunto[]>) => {
            if (response) {
              setAssunto(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              //signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar assuntos: " + error.message);
            }
          }),
        recuperarTodasClasses()
          .then((response: AxiosResponse<Classe[]>) => {
            if (response) {
              setClasse(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              //signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar classes: " + error.message);
            }
          }),
        recuperarTodasVaras()
          .then((response: AxiosResponse<Vara[]>) => {
            if (response) {
              setVara(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              //signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar varas: " + error.message);
            }
          }),
      ])
        .then(() => {
          return recuperarProcessoPorId(id)
            .then((response: AxiosResponse<ProcessoResponseDTO>) => {
              if (response) {
                setProcesso(response.data);
              }
            })
            .catch((error: any) => {
              if (error.response && error.response.status === 401) {
                toast.info("Desconectado por inatividade");
                //signout();
                navigate("/");
              } else {
                toast.error("Erro ao recuperar processo: " + error.message);
              }
            });
        })
        .catch((error) => {
          toast.error("Erro ao carregar dados iniciais: " + error.message);
        });
    }
  }, [id]);

  const handleCancelar = () => {
    navigate(-1);
  };

  const handleSalvar = () => {
    setIsLoading(true);
    if (id) {
      editarProcesso(id, processo)
        .then((response: AxiosResponse<Response>) => {
          if (response) {
            toast.success("Processo atualizado com sucesso!");
            navigate(-1);
          }
        })
        .catch((error) => {
          let errorMessage = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.response
          ) {
            errorMessage += error.response.data.response;
          } else {
            errorMessage += error.message;
          }
          toast.error(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleAdvogadoSelectionChange = (selected: Advogado[]) => {
    setProcesso({
      ...processo,
      advogados: selected,
    });
  };

  return (
    <Layout>
      <form autoComplete="off" noValidate>
        <Card>
          <CardHeader
            subheader="Essas informações podem ser editadas"
            title={id ? "Editar" : "Cadastrar"}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Classe"
                    name="classeId"
                    select
                    SelectProps={{ native: true }}
                    value={processo.classeId ?? ""}
                    onChange={(event) =>
                      setProcesso({
                        ...processo,
                        classeId: Number(event.target.value),
                      })
                    }
                  >
                    <option key={0} value="">
                      {"Selecione"}
                    </option>
                    {classe.map((c) => (
                      <option key={c.id} value={c.id || ""}>
                        {c.descricao}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Assunto"
                    name="assuntoId"
                    select
                    SelectProps={{ native: true }}
                    value={processo.assuntoId ?? ""}
                    onChange={(event) =>
                      setProcesso({
                        ...processo,
                        assuntoId: Number(event.target.value),
                      })
                    }
                  >
                    <option key={0} value="">
                      {"Selecione"}
                    </option>
                    {assunto.map((a) => (
                      <option key={a.id} value={a.id || ""}>
                        {a.descricao}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Vara"
                    name="varaId"
                    select
                    SelectProps={{ native: true }}
                    value={processo.varaId ?? ""}
                    onChange={(event) =>
                      setProcesso({
                        ...processo,
                        varaId: Number(event.target.value),
                      })
                    }
                  >
                    <option key={0} value="">
                      {"Selecione"}
                    </option>
                    {vara.map((v) => (
                      <option key={v.id} value={v.id || ""}>
                        {v.descricao}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Área"
                    name="areaId"
                    select
                    SelectProps={{ native: true }}
                    value={processo.areaId ?? ""}
                    onChange={(event) =>
                      setProcesso({
                        ...processo,
                        areaId: Number(event.target.value),
                      })
                    }
                  >
                    <option key={0} value="">
                      {"Selecione"}
                    </option>
                    {area.map((a) => (
                      <option key={a.id} value={a.id || ""}>
                        {a.descricao}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    required
                    value={processo.numero}
                    onChange={(event) =>
                      setProcesso({ ...processo, numero: event.target.value })
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Data Criação"
                    name="dataCriacao"
                    required
                    value={processo.dataCriacao}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Valor"
                    name="valor"
                    type="number"
                    value={processo.valor ?? ""}
                    onChange={(event) =>
                      setProcesso({
                        ...processo,
                        valor: Number(event.target.value),
                      })
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    fullWidth
                    label="Conteúdo"
                    name="conteudo"
                    required
                    multiline
                    rows={6}
                    value={processo.conteudo}
                    onChange={(event) =>
                      setProcesso({ ...processo, conteudo: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Autor"
                    name="autor"
                    value={processo.autor}
                    onChange={(event) =>
                      setProcesso({ ...processo, autor: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Réu"
                    name="reu"
                    value={processo.reu}
                    onChange={(event) =>
                      setProcesso({ ...processo, reu: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <AdvogadoGrid
                    advogados={processo.advogados}
                    selectedAdvogados={processo.advogados || []}
                    onSelectionChange={handleAdvogadoSelectionChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              type="button"
              variant="contained"
              disabled={isLoading}
              onClick={handleSalvar}
            >
              {isLoading ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
            <Button type="button" variant="outlined" onClick={handleCancelar}>
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default TelaProcesso;
