import BoltIcon from "@heroicons/react/24/solid/BoltIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ConfirmDialog from "../../components/confirmDialog";
import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import { MuiTable } from "../../components/table";
import useAuth from "../../hooks/useAuth";
import {
  recuperarTodosProcessos,
  deletarProcesso,
} from "../../services/processo";
import {
  deletarUsuarioPorId,
  recuperarHierarquiaPorId,
} from "../../services/usuario";
import {
  ProcessoListDTO,
  AxiosResponse,
  Response,
  HierarquiaUsuario,
} from "../../types";

const ListagemProcesso: FC = () => {
  const navigate = useNavigate();
  const { user, signout, setEditUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmBlockOpen, setConfirmBlockOpen] = useState(false);
  const [processos, setProcessos] = useState<ProcessoListDTO[]>([]);
  const [hierarquias, setHierarquias] = useState<HierarquiaUsuario[]>([]);

  const fetchHierarquia = async (
    userId: string | undefined,
    tipoUsuario: string,
  ) => {
    if (!userId) {
      toast.info("ID do usuário não fornecido para fetchHierarquia.");
      return;
    }

    try {
      const responseHierarquia = await recuperarHierarquiaPorId(
        userId,
        tipoUsuario,
      );
      if (responseHierarquia) {
        setHierarquias(responseHierarquia.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      } else {
        toast.error("Erro ao recuperar hierarquia:" + error);
      }
    }
  };

  const fetchProcessos = async () => {
    try {
      const response = await recuperarTodosProcessos();
      if (response) {
        setProcessos(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar todos os usuários:" + error);
      }
    }
  };

  const fetchData = async () => {
    if (user?.idUsuario) {
      await Promise.all([fetchProcessos()]);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchData();
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAdd = () => {
    const userToEdit = {
      idUsuario: null,
      nome: "",
      dataVencimento: "",
    };
    //setEditUser(userToEdit);
    navigate(`/usuario/editar`);
  };

  const handleAddRapido = () => {
    const userToEdit = {
      idUsuario: null,
      nome: "",
      dataVencimento: "",
    };
    //setEditUser(userToEdit);
    navigate(`/usuario/editarRapido`);
  };

  const handleDelete = async (id: string) => {
    try {
      Promise.all([
        deletarProcesso(id).then((response: AxiosResponse<Response>) => {
          if (response) {
            fetchData();
            toast.success("Processo deletado(a) com sucesso!");
          }
        }),
      ]);
    } catch (error) {
      toast.error("Erro ao deletar processo: " + error);
    }
  };

  const filteredData = processos.filter(
    (item) =>
      item.numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.conteudo.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleHierarquiaChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selectedValue = event.target.value as string;
    const userIdToUse = selectedValue === "0" ? user?.idUsuario : selectedValue;

    if (userIdToUse) {
      //await fetchUsuariosHierarquia(userIdToUse);
    } else {
      toast.info("Nenhum ID de usuário disponível para buscar hierarquia.");
    }
  };

  const handleSelectedIdsChange = (selectedIdss: string[]) => {
    setSelectedIds(selectedIdss);
  };

  const handleBlockClick = () => {
    setConfirmBlockOpen(true);
  };

  const handleCancelBlock = () => {
    setConfirmBlockOpen(false);
  };

  const handleActionChange = (event: React.ChangeEvent<any>) => {
    const action = event.target.value as string;

    if (action === "alterarVencimento") {
      setModalOpen(true);
    }

    if (action === "bloquear") {
      handleBlockClick();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Processos</Typography>
              </Stack>
              <div>
                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{ marginLeft: "16px" }}
                  onClick={handleAdd}
                >
                  Cadastro
                </Button> */}
              </div>
            </Stack>
            <Search
              placeholder={"Pesquisar por número ou conteúdo"}
              onChange={handleSearchChange}
            />
            <MuiTable
              items={filteredData}
              onDelete={handleDelete}
              onSelectionChange={handleSelectedIdsChange}
            />
          </Stack>
          <ConfirmDialog
            isOpen={confirmBlockOpen}
            title="Confirmar Bloqueio"
            content="Tem certeza que deseja bloquear este usuário e toda a sua hierarquia?"
            onConfirm={handleCancelBlock}
            onCancel={handleCancelBlock}
          />
        </Container>
      </Box>
    </Layout>
  );
};

export default ListagemProcesso;
