import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

interface ProcessoContentDialogProps {
  conteudo: string;
  open: boolean;
  onClose: () => void;
}

const ProcessoContentDialog: React.FC<ProcessoContentDialogProps> = ({
  conteudo,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Conteúdo do Processo</DialogTitle>
      <DialogContent>
        <p>{conteudo}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessoContentDialog;
