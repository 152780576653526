import type { Assunto, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_ASSUNTO = "/v1/assunto";

export async function recuperarTodosAssuntos(): Promise<
  AxiosResponse<Assunto[]>
> {
  return await painelApi.get(PATH_V1_ASSUNTO);
}
