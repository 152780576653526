import type { Classe, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_CLASSE = "/v1/classe";

export async function recuperarTodasClasses(): Promise<
  AxiosResponse<Classe[]>
> {
  return await painelApi.get(PATH_V1_CLASSE);
}
