import type {
  ProcessoDTO,
  ProcessoResponseDTO,
  AxiosResponse,
  Response,
  ProcessoListDTO,
} from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_PROCESSO = "/v1/processo";

export async function recuperarTodosProcessos(): Promise<
  AxiosResponse<ProcessoListDTO[]>
> {
  return await painelApi.get(PATH_V1_PROCESSO);
}

export async function recuperarProcessoPorId(
  codigo: string,
): Promise<AxiosResponse<ProcessoResponseDTO>> {
  return await painelApi.get(`${PATH_V1_PROCESSO}/${codigo}`);
}

export async function adicionarProcessos(
  request: ProcessoDTO[],
): Promise<AxiosResponse<Response>> {
  return await painelApi.post(
    `${PATH_V1_PROCESSO}/adicionarProcessos`,
    request,
  );
}

export async function editarProcesso(
  codigo: string,
  request: ProcessoResponseDTO,
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_PROCESSO}/${codigo}`, request);
}

export async function deletarProcesso(
  codigo: string,
): Promise<AxiosResponse<Response>> {
  return await painelApi.delete(`${PATH_V1_PROCESSO}/${codigo}`);
}
