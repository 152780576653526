import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { OverviewTotalCustomers } from "../../components/overview/overview-customers";
import useAuth from "../../hooks/useAuth";
import { recuperarStatus } from "../../services/usuario";
import { StatusCount } from "../../types";

const HomeAdmin: FC = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [usuariosOnline, setUsuariosOnline] = useState<StatusCount>();

  const fetchStatus = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await recuperarStatus(id);
      if (response && response.data) {
        setUsuariosOnline(response.data);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os usuários:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      //fetchStatus(user.idUsuario);
    }
  }, [user]);

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Bem vindo
              </Typography>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Quantidade de conexões de usuários com Data de Vencimento maior que hoje e não estejam bloqueados."
                arrow
                key={`tooltip-ativos-conexao`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Perícias Ativas"
                    bgColor="success.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosAtivosPorConexao ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários com Data de Vencimento menor que hoje."
                arrow
                key={`tooltip-vencidos`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Canceladas"
                    bgColor="error.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosVencidos ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários que estejam bloqueados."
                arrow
                key={`tooltip-bloqueados`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Bloqueadas"
                    bgColor="info.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosBloqueados ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários Teste com data de vencimento maior que hoje."
                arrow
                key={`tooltip-teste`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Teste"
                    bgColor="primary.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosTeste ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeAdmin;
