import { CircularProgress, Box } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import HomeBackup from "../pages/backup/homeBackup";
import HomeExportar from "../pages/exportar/homeExportar";
import HomeAdmin from "../pages/home/homeAdmin";
import HomeImportar from "../pages/importar/homeImportar";
import ListagemProcesso from "../pages/processo/listagemProcesso";
import TelaProcesso from "../pages/processo/processo";
import HomeRevenda from "../pages/revenda/homeRevenda";
import Signin from "../pages/signIn";

interface PrivateProps {
  Item: FC;
}

const AdminPrivate: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed && user?.tipo === "ADMIN" ? <Item /> : <Navigate to="/" />;
};

const RevendaPrivate: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed && user?.tipo === "REVENDA" ? <Item /> : <Navigate to="/" />;
};

const PrivateRoute: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed ? <Item /> : <Navigate to="/" />;
};

const RoutesApp: FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route
          path="/adminPrivate"
          element={<AdminPrivate Item={HomeAdmin} />}
        />
        <Route path="/home" element={<PrivateRoute Item={HomeAdmin} />} />
        <Route
          path="/processos"
          element={<PrivateRoute Item={ListagemProcesso} />}
        />
        <Route
          path="/processo/:id"
          element={<AdminPrivate Item={TelaProcesso} />}
        />
        <Route path="/revenda" element={<PrivateRoute Item={HomeRevenda} />} />
        <Route
          path="/importar"
          element={<AdminPrivate Item={HomeImportar} />}
        />
        <Route
          path="/exportar"
          element={<AdminPrivate Item={HomeExportar} />}
        />
        <Route path="/backup" element={<AdminPrivate Item={HomeBackup} />} />
        <Route
          path="/revendaPrivateExample"
          element={<RevendaPrivate Item={HomeRevenda} />}
        />
        <Route path="*" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
