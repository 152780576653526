import { AxiosResponse } from "axios";

import { painelApi } from "../painelApi";

const PATH_IMPORT_ARQUIVO = "/v1/importTJSP";

export async function uploadArquivo(file: File): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", file.name);

  return await painelApi.post(PATH_IMPORT_ARQUIVO, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
