import {
  Bolt as BoltIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";

import AdvogadoDetailsDialog from "../components/advogadoDetailsDialog";
import ProcessoContentDialog from "../components/processoContentDialog";
import type { ProcessoDTO, Advogado } from "../types";

interface ProcessosTableProps {
  processos: ProcessoDTO[];
  onEditFast: (row: ProcessoDTO) => void;
  onEditClick: (row: ProcessoDTO) => void;
  onDeleteClick: (row: ProcessoDTO) => void;
}

const ProcessosTable: React.FC<ProcessosTableProps> = ({
  processos,
  onEditFast,
  onEditClick,
  onDeleteClick,
}) => {
  const [selectedAdvogado, setSelectedAdvogado] = useState<Advogado | null>(
    null,
  );
  const [selectedProcessoContent, setSelectedProcessoContent] = useState<
    string | null
  >(null);
  const [advogadoDialogOpen, setAdvogadoDialogOpen] = useState(false);
  const [conteudoDialogOpen, setConteudoDialogOpen] = useState(false);

  const handleAdvogadoClickOpen = (advogado: Advogado) => {
    setSelectedAdvogado(advogado);
    setAdvogadoDialogOpen(true);
  };

  const handleAdvogadoDialogClose = () => {
    setAdvogadoDialogOpen(false);
    setSelectedAdvogado(null);
  };

  const handleConteudoClickOpen = (conteudo: string) => {
    setSelectedProcessoContent(conteudo);
    setConteudoDialogOpen(true);
  };

  const handleConteudoDialogClose = () => {
    setConteudoDialogOpen(false);
    setSelectedProcessoContent(null);
  };

  const columns: GridColDef[] = [
    {
      field: "numero",
      headerName: "Número",
      width: 230,
      headerAlign: "center",
    },
    {
      field: "conteudo",
      headerName: "Conteúdo",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Ver Conteúdo Completo">
            <IconButton
              onClick={() => handleConteudoClickOpen(params.row.conteudo)}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "advogado1",
      headerName: "Advogado 1",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[0];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton
              onClick={() => handleAdvogadoClickOpen(advogado)}
              aria-label="Ver detalhes"
            >
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      field: "advogado2",
      headerName: "Advogado 2",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[1];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton
              onClick={() => handleAdvogadoClickOpen(advogado)}
              aria-label="Ver detalhes"
            >
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      field: "advogado3",
      headerName: "Advogado 3",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[2];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton
              onClick={() => handleAdvogadoClickOpen(advogado)}
              aria-label="Ver detalhes"
            >
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      headerAlign: "center",
      renderCell: (params) => [
        <Tooltip title="Excluir" arrow key={`${params.row.id}-delete-tooltip`}>
          <GridActionsCellItem
            key={`${params.row.id}-delete`}
            icon={<DeleteIcon />}
            label="Excluir"
            onClick={() => onDeleteClick(params.row)}
            color="inherit"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={processos}
          columns={columns}
          checkboxSelection
          pagination
          getRowId={(row) => row.numero}
        />
      </Box>
      <AdvogadoDetailsDialog
        advogado={selectedAdvogado}
        open={advogadoDialogOpen}
        onClose={handleAdvogadoDialogClose}
      />
      <ProcessoContentDialog
        conteudo={selectedProcessoContent || ""}
        open={conteudoDialogOpen}
        onClose={handleConteudoDialogClose}
      />
    </>
  );
};

export default ProcessosTable;
